import React from 'react';
import styled from 'styled-components';

import Particles, { ParticlesContainer } from './particles';
import Button from '../button';

import layoutStyle from '../../styles/layout';
import { colors, media } from '../../styles/constants';
import atGif from '../../assets/at-gif.gif';

const AssetManagementHeaderContainer = styled.div`
  margin-top: 3.75rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledParticlesContainer = styled(ParticlesContainer)`
  .particles canvas {
    opacity: 0.5;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  transform: translateZ(1px);

  ${layoutStyle}

  @media (min-width: ${media.tabletPortrait}px) {
    flex-direction: row;
    text-align: left;
  }

  h1 {
    padding-bottom: 1rem;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 1.75rem;
    }
  }


  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.lightGrey};

    @media (min-width: ${media.tabletPortrait}px) {
      align-items: normal;
      width: 55%;
    }

    p {
      max-width: 55ch;

        padding-bottom: 1rem;

        @media (min-width: ${media.tabletPortrait}px) {
          padding-bottom: 1.75rem;
        }
      }
    }

    p:nth-child(2) {
      @media (max-width: ${media.tabletPortrait}px) {
        display: none;
      }
    }
  }

  > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;

    img {
      max-width: 100%;
    }

    @media (min-width: ${media.tabletPortrait}px) {
      padding-top: 0;
      width: 43%;
      max-width: 600px;

      img {
        margin-right: -6rem;
        width: 90%;
      }

      svg {
        margin-right: -4rem;
        width: 90%;
      }
    }
  }
`;

function AssetManagementHeader() {
  return (
    <AssetManagementHeaderContainer>
      <Particles Container={StyledParticlesContainer}>
        <TitleContainer>
          <div>
            <h1>Inventory Management</h1>
            <p>
              The Inventory Management solution provides companies with advanced
              asset visibility and streamlined management capabilities. By
              utilizing affordable active tags, you can track assets in
              real-time, including inventory status, location, and circular
              supply chain oversight.
            </p>
            <p>
              This transformative technology automates labor-intensive tasks
              like inbound/outbound picking and asset inventory, offering a
              range of applications to monitor the location of fixed assets.
            </p>
            <Button
              secondary
              onClick={() => {
                const element = document.getElementById('content');
                if (element) {
                  element.scrollIntoView({
                    behavior: 'smooth'
                  });
                }
              }}
            >
              Learn More
            </Button>
          </div>
          <div>
            <img
              src={atGif}
              alt="Visual representation of the Tugger Train Tracking System"
            />
          </div>
        </TitleContainer>
      </Particles>
    </AssetManagementHeaderContainer>
  );
}

export default AssetManagementHeader;
