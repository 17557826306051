import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/headers/assetManagement';
import IconList from '../components/iconList';
import HowItWorks from '../components/howItWorks';
import ContactForm from '../components/contactForm';

import OutstandingRangeIcon from '../assets/svg/Outstanding_Range.svg';
import MaximizesBatteryLifetimeIcon from '../assets/svg/Maximizes_Battery_Lifetime.svg';
import NoMissedReadingsIcon from '../assets/svg/No_Missed_Readings.svg';
import NoWifiInterferenceIcon from '../assets/svg/No_Wifi_Interference.svg';

import { colors, media } from '../styles/constants';
import layoutStyle from '../styles/layout';
import AssetLogos from '../components/assetLogos';

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  ${layoutStyle}

  @media (min-width: ${media.tabletPortrait}px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  > div:first-child {
    flex: 1 0 100%;
    width: 100%;

    @media (min-width: ${media.tabletPortrait}px) {
      margin-right: 4rem;
      flex: 1 0 65%;
    }
  }

  hr {
    border: solid 1px ${colors.blue};
    border-radius: 5px;
    opacity: 0.5;
    max-width: 40rem;
    width: 100%;
    margin: 2rem 0;

    @media (min-width: ${media.tabletPortrait}px) {
      display: none;
    }
  }

  > div:last-child {
    flex: 1 0 50%;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      flex: 0 1 45%;
    }

    h3 {
      line-height: 1.2;
      text-align: center;
      color: ${colors.blue};
      font-size: 1.5rem;
    }

    span {
      font-size: 1.25rem;
    }
  }
`;

function AssetManagement({ location: { pathname } }) {
  const data = useStaticQuery(graphql`
    query {
      anchor: file(relativePath: { eq: "anchor-at.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tag: file(relativePath: { eq: "small-tag-m.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      dashboard: file(relativePath: { eq: "asset-management-dashboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Inventory Management" pathname={pathname} />
      <Layout Header={<Header />}>
        <IconList
          title="Our Proprietary IoT Technology Enables"
          fixedHeight
          list={[
            {
              Icon: OutstandingRangeIcon,
              title: 'Outstanding Range',
              text:
                'Tag detection up to 200m enables exceptional coverage with minimal infrastructure'
            },
            {
              Icon: MaximizesBatteryLifetimeIcon,
              title: 'Great Battery Life',
              text:
                '6 years tag battery lifetime that minimizes costs and facilitates maintenance'
            },
            {
              Icon: NoMissedReadingsIcon,
              title: 'No Missed Readings',
              text:
                'Each Azitek Gateway is able to monitor more than 50,000 Tags in real-time'
            },
            {
              Icon: NoWifiInterferenceIcon,
              title: 'No WiFi Interference',
              text:
                'Sub-GHz proprietary protocol avoids interference with WiFi and BLE spectrum'
            }
          ]}
        />
        <HowItWorks
          list={[
            {
              title: 'Each asset is equipped with an affordable Active IoT Tag',
              bullets: [
                'Enable companies to track and locate every operational asset',
                'With a size of 6x42x25mm it blends perfectly with assets of any size and shape',
                'High operational lifetime of 6 years'
              ],
              image: data.tag.childImageSharp.fluid
            },
            {
              title:
                'Anchor Gateways are installed on industrial facilities, warehouses and vehicles',
              bullets: [
                'Monitoring capacity of over 50,000 Tags combined with a reading range of 200m leads to virtually no missed readings',
                'Use existing Wi-Fi infrastructure for hassle free deployment',
                'Onboard memory prevents data loss during Wifi outage'
              ],
              image: data.anchor.childImageSharp.fluid
            },
            {
              title:
                'Cloud Dashboard enables real-time analytics, warnings, and ERP/WMS Integrations',
              bullets: [
                'Access to asset status and history',
                'Multiple accounts with different privileges',
                'API available for client integration'
              ],
              image: data.dashboard.childImageSharp.fluid
            }
          ]}
        />
        <AssetLogos />
        <FormContainer>
          <ContactForm
            title="Interested in a demo? Leave us a
          message"
            page="Asset Management"
          />
          <hr />
        </FormContainer>
      </Layout>
    </>
  );
}

AssetManagement.propTypes = {
  location: PropTypes.object.isRequired
};

export default AssetManagement;
